import * as React from "react";
import {
  NumberField,
  ReferenceField,
  SimpleShowLayout,
  TextField
} from "react-admin";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";

export function IncomingMaterialQuickPreview(props) {
  return (
    <Grid container spacing={8}>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <ReferenceField
            source="client_id"
            linkType={false}
            reference="clients"
          >
            <TextField source="company_name" label="Client" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <ReferenceField
            source="vendor_id"
            linkType={false}
            reference="vendors"
          >
            <TextField source="company_name" label="Vendor" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <ReferenceField
            source="product_id"
            linkType={false}
            reference="products"
          >
            <TextField source="name" label="Product" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <TextField source="chamber" label="Chamber" />
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <TextField source="cell_number" label="Cell No." />
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/incoming_materials"
          resource="incoming_materials"
          id={props.id}
        >
          <NumberField source="number_of_bags" label="No. of Bags" />
        </SimpleShowLayout>
      </Grid>
      <Grid item>
        <SimpleShowLayout
          {...props}
          basePath="/balance"
          resource="balance"
          id={props.id}
        >
          <NumberField source="balance_number_of_bags" label="Remaining Bags" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
}

function mapStateToProps(state, props) {
  return {
    record: state.admin.resources["incoming_materials"]
      ? state.admin.resources["incoming_materials"].data[props.id]
      : null
  };
}

export default connect(
  mapStateToProps,
  null
)(IncomingMaterialQuickPreview);
