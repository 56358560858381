import React, { Component } from "react";
import ShowChart from "@material-ui/icons/ShowChart";
import Refresh from "@material-ui/icons/Refresh";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Card from "@material-ui/core/Card";
import Select from "@material-ui/core/Select";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import formatRelative from "date-fns/formatRelative";

import "./index.css";
import providers from "../../providers";
import { getFormattedDateForPostgres } from "../../utils/dates";
import { downloadCSV } from "react-admin";

const { dataProvider } = providers;

const chambers = [
  {
    name: "Chamber E",
    values: ["pv01", "pv02", "pv03"],
  },
  {
    name: "Chamber F",
    values: ["pv04", "pv05", "pv06"],
  },
  {
    name: "Chamber G",
    values: ["pv07", "pv08", "pv09"],
  },
  {
    name: "Chamber H",
    values: ["pv10", "pv11", "pv12"],
  },
  {
    name: "Chamber I",
    values: ["pv13", "pv14", "pv15"],
  },
  {
    name: "Chamber J",
    values: ["pv16", "pv17", "pv18"],
  },
  {
    name: "Chamber K",
    values: ["pv19", "pv20", "pv21"],
  },
];

const REFRESH_TIMER = 60 * 1000;
export class SensorValuesList extends Component {
  state = {
    chamber: 0,
    data: {
      temperature: "...",
      humidity: "...",
      pressure: "...",
      timestamp: null,
    },
  };

  refreshTimer = null;

  componentDidMount() {
    this.getData().then();
    this.refreshTimer = setInterval(() => {
      this.getData().then();
    }, REFRESH_TIMER);
  }

  componentWillUnmount() {
    this.refreshTimer && clearInterval(this.refreshTimer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.chamber !== prevState.chamber) {
      this.getData().then();
    }
  }

  getData = async () => {
    this.setState({
      data: {
        temperature: "...",
        humidity: "...",
        pressure: "...",
        timestamp: null,
      },
    });
    const { chamber } = this.state;
    const response = await dataProvider("GET_LIST", "sensor_data", {
      sort: { field: "timestamp", order: "desc" },
      filter: {
        select: chambers[chamber].values.concat("timestamp").join(","),
      },
      pagination: { page: 1, perPage: 1 },
    });
    const data = {
      temperature: response.data[0][chambers[chamber].values[0]],
      humidity: response.data[0][chambers[chamber].values[1]],
      pressure: response.data[0][chambers[chamber].values[2]],
      timestamp: formatRelative(
        new Date(response.data[0].timestamp),
        new Date()
      ),
    };
    this.setState({ data });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      data: {
        temperature: "...",
        humidity: "...",
        pressure: "...",
        timestamp: null,
      },
    });
  };

  downloadHistory = async () => {
    const chamber = chambers[this.state.chamber];
    const today = new Date();
    const weekAgo = new Date().setDate(today.getDate() - 7);
    const fromDate = getFormattedDateForPostgres(weekAgo);

    const data = await dataProvider("GET_LIST", "sensor_data", {
      sort: { field: "timestamp", order: "desc" },
      filter: {
        select: chamber.values.concat("timestamp").join(","),
        "timestamp@gt": fromDate,
      },
      headers: {
        Range: undefined,
        Accept: "text/csv",
      },
    });
    downloadCSV(data, `Sensor Value - ${chamber.name} - ${fromDate}`);
  };

  render() {
    return (
      <Grid container spacing={24}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FormControl>
            <InputLabel htmlFor="chamber">Chamber</InputLabel>
            <Select
              value={this.state.chamber}
              onChange={this.handleChange}
              inputProps={{
                name: "chamber",
                id: "chamber",
              }}
            >
              {chambers.map((choice, idx) => (
                <MenuItem value={idx} key={choice.name}>
                  {choice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Card>
            <CardHeader
              title={chambers[this.state.chamber].name}
              subheader={this.state.data.timestamp}
              action={
                <IconButton onClick={this.getData}>
                  <Refresh />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="headline" color="textSecondary">
                Temperature
              </Typography>
              <Typography variant="display3" color="textPrimary" gutterBottom>
                {this.state.data.temperature}&#8451;
              </Typography>
              <Typography variant="headline" color="textSecondary">
                Humidity
              </Typography>
              <Typography variant="display3" color="textPrimary" gutterBottom>
                {this.state.data.humidity}%
              </Typography>
              <Typography variant="headline" color="textSecondary">
                Pressure Differential
              </Typography>
              <Typography variant="display3" color="textPrimary" gutterBottom>
                {this.state.data.pressure}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                onClick={this.downloadHistory}
                style={{ marginLeft: "auto" }}
                size="large"
                variant="outlined"
              >
                Download History
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export const SensorValuesIcon = ShowChart;
