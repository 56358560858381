// in src/CustomLayout.js
import { Layout } from "react-admin";
import CustomAppBar from "../customAppBar";
import CustomMenu from "../customMenu";
import React from "react";

const CustomLayout = props => (
  <Layout {...props} menu={CustomMenu} appBar={CustomAppBar} />
);

export default CustomLayout;
