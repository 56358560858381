import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import Show from "./Show";
import ArrowRight from "mdi-material-ui/ArrowRightBoldBoxOutline";

export const FormList = List;
export const FormEdit = Edit;
export const FormCreate = Create;
export const FormShow = Show;
export const FormIcon = ArrowRight;
