import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  EmailField,
  SimpleForm,
  TextInput
} from "react-admin";
import Domain from "@material-ui/icons/Domain";
import CartIcon from "mdi-material-ui/CartArrowUp";

export const ClientList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="company_name" />
      <TextField source="address" />
      <TextField source="city" />
      <TextField source="pincode" />
      <TextField source="district" />
      <TextField source="state" />
      <EmailField source="email" />
      <TextField source="phone" />
      <TextField source="gstin" />
    </Datagrid>
  </List>
);

export const ClientEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="Company Name" source="company_name" />
      <TextInput label="Address" source="address" />
      <TextInput label="City" source="city" />
      <TextInput label="Pincode" source="pincode" />
      <TextInput label="District" source="district" />
      <TextInput label="State" source="state" />
      <TextInput label="Email" source="email" />
      <TextInput label="Phone" source="phone" />
      <TextInput label="GSTIN" source="gstin" />
    </SimpleForm>
  </Edit>
);

export const ClientCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="Company Name" source="company_name" />
      <TextInput label="Address" source="address" />
      <TextInput label="City" source="city" />
      <TextInput label="Pincode" source="pincode" />
      <TextInput label="District" source="district" />
      <TextInput label="State" source="state" />
      <TextInput label="Email" source="email" />
      <TextInput label="Phone" source="phone" />
      <TextInput label="GSTIN" source="gstin" />
    </SimpleForm>
  </Create>
);

export const ClientIcon = Domain;
export const VendorIcon = CartIcon;
