import React from "react";
import Button from "@material-ui/core/Button";
import withStyles from "@material-ui/core/styles/withStyles";

import axios from "axios";

const style = {
  button: {
    marginBottom: "10px"
  }
};

class FileDownload extends React.Component {
  onClick = async ev => {
    ev.stopPropagation();
    const recordElement = this.props.record[this.props.source];
    const response = await axios.post(
      "https://gub1pjgc00.execute-api.us-east-1.amazonaws.com/latest/files",
      {
        id: recordElement,
        method: "getObject"
      }
    );
    window.open(response.data.signedUrl, "_blank");
  };

  render() {
    const {
      source,
      record,
      classes,
      variant = "outlined",
      color = "primary",
      size
    } = this.props;
    const recordElement = record[source];
    return recordElement ? (
      <Button
        className={classes.button}
        variant={variant}
        color={color}
        size={size}
        onClick={this.onClick}
      >
        {this.props.label}
      </Button>
    ) : null;
  }
}

export default withStyles(style)(FileDownload);
