import Create from "./Create";
import Edit from "./Edit";
import List from "./List";
import ArrowLeft from "mdi-material-ui/ArrowLeftBoldBoxOutline";
import Show from "./Show";

export const OutgoingMaterialList = List;
export const OutgoingMaterialShow = Show;
export const OutgoingMaterialEdit = Edit;
export const OutgoingMaterialCreate = Create;
export const OutgoingMaterialIcon = ArrowLeft;
