import React from "react";
import { Edit, SimpleForm } from "react-admin";

import Form from "./Form";

export default class FormCreate extends React.Component {
  render() {
    const { props } = this;
    return (
      <Edit {...props} title="Incoming Material">
        <SimpleForm redirect="list">
          <Form />
        </SimpleForm>
      </Edit>
    );
  }
}
