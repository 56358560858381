import React from "react";

import {
  DateField,
  EditButton,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField
} from "react-admin";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import pick from "lodash/pick";
import FileCopy from "@material-ui/icons/FileCopy";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const leftIconStyle = {
  marginRight: 10
};

const FormShowActions = props => {
  const { basePath, data } = props;
  const cloneFields = [
    "date",
    "unloadTime",
    "vehicleNumber",
    "client",
    "vendor",
    "invoiceNo",
    "invoiceDate",
    "invoiceWeight"
  ];
  return (
    <CardActions style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} />

      <Button
        component={Link}
        to={{
          pathname: "/forms/create",
          state: { record: pick(data, cloneFields) }
        }}
        color="primary"
        size="small"
      >
        <FileCopy style={leftIconStyle} />
        Create Similar Record
      </Button>
    </CardActions>
  );
};
export const FormShow = props => {
  return (
    <Show {...props} actions={<FormShowActions />} title="Incoming Material">
      <SimpleShowLayout>
        <NumberField source="goodsReceiptNo" label="Goods Receipt No." />
        <DateField source="date" label="Unload Date" />
        <DateField
          source="unloadTime"
          label="Unload Time"
          showTime
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <ReferenceField source="client" linkType={false} reference="clients">
          <TextField source="companyName" label="Client" />
        </ReferenceField>
        <ReferenceField source="vendor" linkType={false} reference="vendors">
          <TextField source="companyName" label="Vendor" />
        </ReferenceField>
        <TextField source="vehicleNumber" label="Vehicle No." />
        <TextField source="chamber" label="Chamber" />
        <TextField source="cellNumber" label="Cell No." />
        <ReferenceField source="product" linkType={false} reference="products">
          <TextField source="name" label="Product" />
        </ReferenceField>
        <NumberField source="numberOfBags" label="No. of Bags" />
        <TextField source="invoiceNo" label="Invoice No." />
        <DateField source="invoiceDate" label="Invoice Date" />
        <NumberField source="invoiceWeight" label="Invoice Wt." />
        <NumberField source="avgWeight" label="Average Wt." />
        <NumberField source="shortageOfBags" label="Shortage of Bags" />
        <TextField source="comments" label="Comments" />
      </SimpleShowLayout>
    </Show>
  );
};

export default FormShow;
