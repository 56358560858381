import React from "react";
import { Filter, ReferenceInput, SelectInput, TextInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Client"
      source="trans_clientid@eq"
      reference="clients"
      perPage={200}
      sort={{ field: "company_name", order: "ASC" }}
    >
      <SelectInput optionText="company_name" label="Client" />
    </ReferenceInput>
    <TextInput source="trans_productname@ilike" label="Product Name" />
    <SelectInput
      source="trans_status@eq"
      label="Status"
      choices={[
        { id: "outgoing", name: "Outgoing" },
        { id: "incoming", name: "Incoming" }
      ]}
    />
  </Filter>
);
