import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import _get from "lodash/get";

// balance_number_of_bags: -1
// balance_weight: -5
// client_id: 7
// id: 328
// incoming_date: "2019-10-11"
// incoming_time: "17:44:00"
// invoice_weight: 3
// number_of_bags: 1
// outgoing_material_ids: [631, 629]
// outgoing_number_of_bags: 2
// outgoing_weight: 8
// product_id: 29
// vendor_id: 15

export default (records, fetchRelatedRecords) => {
  run(fetchRelatedRecords, records).then(({ clients, vendors, products }) => {
    const data = records.map(record => ({
      "Goods Receipt No": record.id,
      Client: clients[record.client_id].company_name,
      Vendor: vendors[record.vendor_id].company_name,
      Product: products[record.product_id].name,
      "Incoming Wt.": record["invoice_weight"],
      "Outgoing Wt.": record["outgoing_weight"],
      "Balance Wt.": record["balance_weight"],
      "Incoming No. Of Bags": record["number_of_bags"],
      "Outgoing No. Of Bags": record["outgoing_number_of_bags"],
      "Balance No. Of Bags": record["balance_number_of_bags"],
      "Outgoing Materials": _get(record, "outgoing_material_ids", []).length
    }));

    const csv = convertToCSV({
      data
    });
    downloadCSV(csv, "Balance Export");
  });
};

async function run(fetchRelatedRecords, records) {
  const clients = await fetchRelatedRecords(records, "client_id", "clients");
  const vendors = await fetchRelatedRecords(records, "vendor_id", "vendors");
  const products = await fetchRelatedRecords(records, "product_id", "products");
  return { clients, vendors, products };
}
