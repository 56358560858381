import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput
} from "react-admin";
import AccountCircle from "@material-ui/icons/AccountCircle";

export const UserList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="username" />
      <ReferenceField
        source="clientId"
        linkType={false}
        reference="clients"
        allowEmpty={true}
      >
        <TextField source="companyName" />
      </ReferenceField>
      <TextField source="permissions" label="Role" />
    </Datagrid>
  </List>
);

function UserForm() {
  return (
    <>
      <TextInput source="username" label="Username" />
      <TextInput type="password" source="password" label="Password" />
      <ReferenceInput
        fullWidth
        source="clientId"
        reference="clients"
        label="Client"
      >
        <SelectInput fullWidth optionText="companyName" label="Client" />
      </ReferenceInput>
      <SelectInput
        fullWidth
        choices={[
          { id: "admin", name: "Admin" },
          { id: "default", name: "Default" },
          { id: "client", name: "Client" },
          { id: "special-client", name: "Special Client" }
        ]}
        source="permissions"
        label="Role"
      />
    </>
  );
}

export const UserEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Edit>
);

export const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <UserForm />
    </SimpleForm>
  </Create>
);

export const UserIcon = AccountCircle;
