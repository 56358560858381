import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import React from "react";

export default function OutgoingButton({ record }) {
  return (
    <Button
      color="primary"
      variant="raised"
      size="small"
      onClick={ev => ev.stopPropagation()}
      component={Link}
      to={{
        pathname: "/outgoing_materials/create",
        state: { record: { variables: [{ incoming_material_id: record.id }] } }
      }}
    >
      Create Outgoing
    </Button>
  );
}
