import React from "react";
import {
  AutocompleteInput,
  Filter,
  ReferenceInput,
  SelectInput,
} from "react-admin";
import { DateInput } from "../../components/dateTimeInput";

export default (props) => (
  <Filter {...props}>
    <ReferenceInput
      fullWidth
      source="incoming_material_id@eq"
      reference="incoming_materials"
      label="Outwards Receipt No"
      perPage={400}
      filterToQuery={(searchText) => ({ id: searchText })}
    >
      <AutocompleteInput fullWidth label="Goods Receipt No" optionText="id" />
    </ReferenceInput>
    <ReferenceInput
      label="Client"
      source="client_id@eq"
      reference="clients"
      perPage={400}
    >
      <SelectInput optionText="company_name" label="Client" />
    </ReferenceInput>
    <ReferenceInput
      label="Product"
      perPage={500}
      source="product_id@eq"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" label="Product" />
    </ReferenceInput>
    <DateInput source="outgoing_date@eq" label="Outgoing Date" />
    <DateInput source="outgoing_date@gte" label="From Date" />
    <DateInput source="outgoing_date@lte" label="To Date" />
  </Filter>
);
