import React from "react";
import { Login, LoginForm } from "react-admin";
import { withStyles } from "@material-ui/core";

const styles = {
  avatar: {
    background: "url(https://harvish.imgix.net/logo.png?height=80)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: 80
  },
  icon: {
    marginLeft: "220px",
    marginTop: "18px"
  }
};

const CustomLoginForm = withStyles({
  button: { background: "#F15922" }
})(LoginForm);

const CustomLoginPage = props => (
  <Login
    loginForm={<CustomLoginForm />}
    backgroundImage="https://harvish.imgix.net/background.jpg?auto=compress&fm=jpg"
    {...props}
  />
);

export default withStyles(styles)(CustomLoginPage);
