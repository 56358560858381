import React, { Component } from "react";
import PropTypes from "prop-types";
import { addField } from "ra-core";
import {
  DatePicker,
  TimePicker,
  DateTimePicker,
  MuiPickersUtilsProvider
} from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import identity from "lodash/identity";

const makePicker = PickerComponent => {
  class _makePicker extends Component {
    onChange(date) {
      this.props.input.onChange(date);
      this.props.input.onBlur();
    }

    render() {
      const {
        input,
        options = {},
        label,
        className,
        meta,
        providerOptions
      } = this.props;

      const { f = identity, rf = identity } = options;

      const { touched, error } = meta;
      return (
        <div className="picker">
          <MuiPickersUtilsProvider {...providerOptions}>
            <PickerComponent
              {...options}
              label={label}
              margin="normal"
              error={!!(touched && error)}
              helperText={touched && error}
              className={className}
              value={input.value ? rf(input.value) : null}
              onChange={date => this.onChange(f(date))}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    }
  }
  _makePicker.propTypes = {
    input: PropTypes.object,
    isRequired: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.object,
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
    labelTime: PropTypes.string,
    className: PropTypes.string,
    providerOptions: PropTypes.shape({
      utils: PropTypes.func,
      locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })
  };

  _makePicker.defaultProps = {
    input: {},
    isRequired: "false",
    label: "",
    meta: { touched: false, error: false },
    options: {},
    resource: "",
    source: "",
    labelTime: "",
    className: "",
    providerOptions: {
      utils: DateFnsUtils,
      locale: undefined,
      awareOfUnicodeTokens: true
    }
  };
  return _makePicker;
};

export const DateInput = addField(makePicker(DatePicker));
export const TimeInput = addField(makePicker(TimePicker));
export const DateTimeInput = addField(makePicker(DateTimePicker));
