import React from "react";
import { withStyles } from "@material-ui/core";
import "./style.css";

import {
  Datagrid,
  Filter,
  List,
  NumberField,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextField
} from "react-admin";
import exporter from "./exporter";

const StockFilters = props => (
  <Filter {...props} style={{ minWidth: "300px" }}>
    <ReferenceArrayInput
      label="Product"
      source="product_id@in"
      reference="products"
      alwaysOn
      perPage={100}
      style={{ width: "100%" }}
      sort={{ field: "name", order: "ASC" }}
      filterToQuery={searchText => ({ "name@ilike": searchText })}
    >
      <AutocompleteArrayInput optionText="name" />
    </ReferenceArrayInput>
  </Filter>
);

const ListWithoutStyles = props => {
  return (
    <List
      {...props}
      title="Stock"
      perPage={100}
      bulkActionButtons={false}
      filters={<StockFilters />}
      sort={{}}
      exporter={exporter}
    >
      <Datagrid rowClick={null}>
        <TextField sortable={false} source="goods_id" />
        <TextField sortable={false} source="client_name" />
        <TextField sortable={false} source="product_name" />

        <NumberField
          sortable={false}
          source="number_of_bags"
          label="Incoming No. of Bags"
        />
        <NumberField
          sortable={false}
          source="outgoing_bags"
          label="Outgoing No. of Bags"
        />
        <NumberField
          sortable={false}
          source="balance_bags"
          label="Remaining No. of Bags"
        />

        <TextField sortable={false} source="cell_number" label="Cell Number" />
        <TextField sortable={false} source="chamber" label="Chamber" />

        <NumberField
          sortable={false}
          source="incoming_date"
          label="Incoming Date"
        />
        <NumberField
          sortable={false}
          source="incoming_time"
          label="Outgoing Time"
        />
      </Datagrid>
    </List>
  );
};
const styles = {
  rowCell: {
    whiteSpace: "nowrap"
  },
  noResults: {
    "&:after": {
      content: `" Choose products to see the inventory results"`
    }
  }
};
export default withStyles(styles)(ListWithoutStyles);
