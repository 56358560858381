import React from "react";
import {
  ArrayInput,
  LongTextInput,
  NumberInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { DateInput, TimeInput } from "../../components/dateTimeInput";
import FileUpload from "../../components/fileUpload";
const shortid = require("shortid");

const generateFileName = () => {
  return `incoming-${shortid.generate()}`;
};
export function Form() {
  return (
    <Grid container spacing={24}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={24}>
          <Grid item>
            <DateInput fullWidth source="incoming_date" label="Unload Date" />
          </Grid>
          <Grid item>
            <TimeInput fullWidth source="incoming_time" label="Unload Time" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <ReferenceInput
          fullWidth
          source="client_id"
          reference="clients"
          perPage={500}
          sort={{ field: "company_name", order: "ASC" }}
        >
          <SelectInput fullWidth optionText="company_name" label="Client" />
        </ReferenceInput>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <ReferenceInput
          fullWidth
          source="vendor_id"
          reference="vendors"
          perPage={500}
          sort={{ field: "company_name", order: "ASC" }}
        >
          <SelectInput
            fullWidth
            optionText="company_name"
            label="Client Source"
          />
        </ReferenceInput>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextInput fullWidth source="vehicle_number" label="Vehicle Number" />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <TextInput fullWidth source="invoice_number" label="Invoice Number" />
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <DateInput source="invoice_date" label="Invoice Date" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ArrayInput source="variables" label="Products Information">
          <SimpleFormIterator>
            <TextInput source="chamber" label="Chamber" />
            <TextInput source="cell_number" label="Cell Number" />
            <ReferenceInput
              perPage={500}
              label="Product"
              source="product_id"
              reference="products"
              sort={{ field: "name", order: "ASC" }}
            >
              <SelectInput optionText="name" label="Product" />
            </ReferenceInput>
            <NumberInput source="invoice_weight" label="Invoice Weight" />
            <NumberInput source="number_of_bags" label="Number of Bags" />
            <NumberInput source="shortage_of_bags" label="Shortage of Bags" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <LongTextInput
          fullWidth
          source="comments"
          label="Any remarks or comments?"
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="invoice_img"
          resource="incoming"
          label="Invoice Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="waybridge_img"
          resource="incoming"
          label="Waybridge Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="other_img"
          resource="incoming"
          label="Any other Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
    </Grid>
  );
}

const formSelector = formValueSelector(REDUX_FORM_NAME);
const mapStateToProps = (state) => ({
  id: formSelector(state, "id"),
});

export default connect(mapStateToProps)(Form);
