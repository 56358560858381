import DateIO from "@date-io/date-fns";

export function getFormattedDate(date) {
  return date ? new DateIO().format(new Date(date), "dd-MM-yyyy") : "";
}

export function getFormattedDateForExcel(date) {
  return date ? new DateIO().format(new Date(date), "dd-MMM-yyyy") : "";
}

export function getFormattedTime(date) {
  return date ? new DateIO().format(new Date(date), "HH:mm a") : "";
}

export function getFormattedDateForPostgres(date) {
  return date ? new DateIO().format(new Date(date), "yyyy-MM-d") : "";
}

export function getFormattedTimeForPostgres(date) {
  return date ? new DateIO().format(new Date(date), "HH:mm") : "";
}
