import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { getFormattedDateForExcel } from "./../../utils/dates";
// client_id: 7
// comments: null
// id: 629
// incoming_material_id: 328
// invoice_img: null
// number_of_bags: 1
// other_img: null
// outgoing_date: "2019-10-25"
// outgoing_time: "15:23:00"
// outward_weight: 3
// product_id: 29
// vehicle_number: "123"
// waybridge_img: null

export default (records, fetchRelatedRecords) => {
  run(fetchRelatedRecords, records).then(({ clients = {}, products = {} }) => {
    const data = records.map(record => {
      const client = clients[record.client_id];
      const product = products[record.product_id];

      return {
        "Outward Receipt No.": record.id,
        "Goods Receipt No.": record.incoming_material_id,
        "Unload Date": getFormattedDateForExcel(record.outgoing_date),
        "Unload Time": record.outgoing_time,
        "Vehicle Number": record.vehicle_number,
        "Number Of Bags": record.number_of_bags,
        "Outward Weight": record.outward_weight,
        Comments: record.comments,
        Client: client.company_name,
        Product: product.name
      };
    });

    const csv = convertToCSV({
      data
    });
    downloadCSV(csv, "Outgoing Materials Export");
  });
};

async function run(fetchRelatedRecords, records) {
  const clients = await fetchRelatedRecords(records, "client_id", "clients");
  const products = await fetchRelatedRecords(records, "product_id", "products");

  return { clients, products };
}
