import React from "react";
import {
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField
} from "react-admin";
import AttachMoney from "@material-ui/icons/AttachMoney";

export const PricesList = props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField source="client" reference="clients" label="Client">
        <TextField source="companyName" />
      </ReferenceField>

      <ReferenceField label="Product" source="product" reference="products">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="price" label="Price" />
      <NumberField source="gst" label="GST" />
      <NumberField source="hamali" label="Hamali" />
    </Datagrid>
  </List>
);

export const PricesEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <ReferenceInput source="client" reference="clients" label="Client">
        <SelectInput optionText="companyName" />
      </ReferenceInput>
      <ReferenceInput source="product" reference="products" label="Product">
        <SelectInput source="name" />
      </ReferenceInput>
      <NumberInput source="price" label="Price" />
      <NumberInput source="gst" label="GST" />
      <NumberInput source="hamali" label="Hamali" />
    </SimpleForm>
  </Edit>
);

export const PricesCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput source="client" reference="clients" label="Client">
        <SelectInput optionText="companyName" />
      </ReferenceInput>
      <ReferenceInput source="product" reference="products" label="Product">
        <SelectInput source="name" />
      </ReferenceInput>
      <NumberInput source="price" label="Price" />
      <NumberInput source="gst" label="GST" />
      <NumberInput source="hamali" label="Hamali" />
    </SimpleForm>
  </Create>
);

export const PricesIcon = AttachMoney;
