import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

// avg_incoming_weight: 50.7953125
// avg_outgoing_weight: null
// balance_bags: 640
// balance_weight: 32509
// client_name: "Harvish cold storage"
// invoice_weight: 32509
// number_of_bags: 640
// outgoing_bags: 0
// outgoing_weight: 0
// product_name: "Turmeric"

export default records => {
  const data = records.map(record => ({
    Client: record.client_name,
    Product: record.product_name,
    "Incoming No. Of Bags": record.number_of_bags,
    "Outgoing No. Of Bags": record.outgoing_bags,
    "No. Of Bags Difference": record.balance_bags,
    "Invoice Weight": record.invoice_weight,
    "Outward Weight": record.outgoing_weight,
    "Remaining Weight": record.balance_weight,
    "Incoming Avg. Wt": record.avg_incoming_weight,
    "Outgoing Avg. Wt": record.avg_outgoing_weight
  }));

  const csv = convertToCSV({
    data
  });
  downloadCSV(csv, "Summary Export");
};
