import React from "react";
import { Filter, NumberInput, ReferenceInput, SelectInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <NumberInput label="Goods Receipt No." source="id" />
    <ReferenceInput perPage={400} source="vendor_id" reference="vendors">
      <SelectInput optionText="company_name" label="Client Source" />
    </ReferenceInput>
    <ReferenceInput perPage={400} source="client_id" reference="clients">
      <SelectInput optionText="company_name" label="Client" />
    </ReferenceInput>
    <ReferenceInput
      perPage={400}
      source="product_id"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" label="Product" />
    </ReferenceInput>
  </Filter>
);
