// in src/Menu.js
import React, { createElement } from "react";
import { connect } from "react-redux";
import { getResources, MenuItemLink, Responsive } from "react-admin";
import { withRouter } from "react-router-dom";

const CustomMenu = ({ resources, onMenuClick, logout }) => (
  <div>
    {resources.map(resource => {
      return (
        resource.hasList && (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={createElement(resource.icon)}
            onClick={onMenuClick}
          />
        )
      );
    })}
    <Responsive
      small={logout}
      medium={null} // Pass null to render nothing on larger devices
    />
  </div>
);

const mapStateToProps = state => ({
  resources: getResources(state)
});

export default withRouter(connect(mapStateToProps)(CustomMenu));
