import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

// balance_bags: 40
// cell_number: "108"
// chamber: "B"
// client_name: "Heera Masala Ganga Containers"
// goods_id: 246
// incoming_date: "2019-09-06"
// incoming_time: "15:00:00"
// number_of_bags: 40
// outgoing_bags: 0
// product_id: 1
// product_name: "Chilli"

export default records => {
  const data = records.map(record => ({
    "Goods Receipt No": record.goods_id,
    Client: record.client_name,
    Product: record.product_name,
    "Incoming Date": record.incoming_date,
    "Incoming Time": record.incoming_time,
    Chamber: record.chamber,
    "Cell Number": record.cell_number,
    "No. Of Bags": record.number_of_bags,
    "Outgoing No. Of Bags": record.outgoing_bags,
    "Balance No. Of Bags": record.balance_bags
  }));

  const csv = convertToCSV({
    data
  });
  downloadCSV(csv, "Balance Export");
};
