import createDataProvider from "./dataProvider";
import createAuthProvider from "./authProvider";

const HOST = process.env.REACT_APP_HOST || "http://localhost:3000";
const AUTH_HOST = process.env.REACT_APP_AUTH_HOST || "http://localhost:3400";

export default {
  dataProvider: createDataProvider({
    host: HOST
  }),
  authProvider: createAuthProvider({
    host: AUTH_HOST
  })
};
