import React from "react";
import {withStyles} from "@material-ui/core";

import {
    ChipField,
    Datagrid,
    List,
    NumberField,
    ReferenceArrayField,
    ReferenceField,
    SingleFieldList,
    TextField
} from "react-admin";
import exporter from "./exporter";
import Filters from "./Filters";
import castArray from "lodash/castArray";


function isAdmin(permissions) {
    return permissions && castArray(permissions).includes("admin");
}

export const FormListWithoutStyles = ({permissions, ...props}) => (
    <List
        {...props}
        bulkActionButtons={false}
        sort={{field: "id", order: "DESC"}}
        title="Balance Sheet"
        perPage={25}
        exporter={exporter}
        filters={<Filters/>}
    >
        <Datagrid rowClick="edit" classes={{rowCell: props.classes.rowCell}}>
            <NumberField source="id" label="Goods Receipt No."/>
            <ReferenceField source="client_id" linkType={false} reference="clients">
                <TextField source="company_name" label="Client"/>
            </ReferenceField>
            <ReferenceField source="vendor_id" linkType={false} reference="vendors">
                <TextField source="company_name" label="Vendor"/>
            </ReferenceField>
            <ReferenceField source="product_id" linkType={false} reference="products">
                <TextField source="name" label="Product"/>
            </ReferenceField>
            <NumberField source="incoming_weight" label="Incoming Wt."/>
            <NumberField source="outgoing_weight" label="Outgoing Wt."/>
            {isAdmin(permissions) && (
                <NumberField source="balance_weight" label="Balance Wt."/>
            )}
            <NumberField source="number_of_bags" label="Incoming No. of Bags"/>
            <NumberField
                source="outgoing_number_of_bags"
                label="Outgoing No. of Bags"
            />
            <NumberField
                source="balance_number_of_bags"
                label="Balance No. of Bags"
            />

            <ReferenceArrayField
                label="Outgoing Material Dates"
                reference="outgoing_materials"
                source="outgoing_material_ids"
                linkType={false}
            >
                <SingleFieldList>
                    <ChipField source="outgoing_date"/>
                </SingleFieldList>
            </ReferenceArrayField>
        </Datagrid>
    </List>
);
const styles = {
    rowCell: {
        whiteSpace: "nowrap"
    }
};
export default withStyles(styles)(FormListWithoutStyles);
