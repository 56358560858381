import React from "react";
import { withStyles } from "@material-ui/core";
import Filter from "./Filter";

import { Datagrid, List, NumberField, TextField } from "react-admin";
import exporter from "./exporter";

export const ListWithoutStyles = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    sort={{ field: "product_name", order: "ASC" }}
    exporter={exporter}
    title="Summary"
    filters={<Filter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="client_name" sortable />
      <TextField source="product_name" sortable />
      <NumberField source="number_of_bags" label="Incoming No. of Bags" />
      <NumberField source="outgoing_bags" label="Outgoing No. of Bags" />
      <NumberField source="balance_bags" label="Remaining No. of Bags" />
      <NumberField source="invoice_weight" label="Incoming Wt." />
      <NumberField source="outgoing_weight" label="Outgoing Wt." />
      <NumberField source="balance_weight" label="Remaining Wt." />
      <NumberField source="avg_incoming_weight" label="Avg Incoming Wt." />
      <NumberField source="avg_outgoing_weight" label="Avg Outgoing Wt." />
    </Datagrid>
  </List>
);
const styles = {
  rowCell: {
    whiteSpace: "nowrap",
  },
};
export default withStyles(styles)(ListWithoutStyles);
