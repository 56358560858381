import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";
import { getFormattedDateForExcel } from "../../utils/dates";

export default (records, fetchRelatedRecords) => {
  run(fetchRelatedRecords, records).then(({ clients, vendors, products }) => {
    const data = records.map(record => ({
      "Goods Receipt No.": record.id,
      "Unload Date": getFormattedDateForExcel(record.incoming_date),
      "Unload Time": record.incoming_time,
      Client: clients[record.client_id].company_name,
      Vendor: vendors[record.vendor_id].company_name,
      "Vehicle Number": record.vehicle_number,
      Chamber: record.chamber,
      "Cell Number": record.cell_number,
      Product: products[record.product_id].name,
      "Number Of Bags": record.number_of_bags,
      "Invoice No.": record.invoice_number,
      "Invoice Date": getFormattedDateForExcel(record.invoice_date),
      "Invoice Weight": record.invoice_weight,
      "Shortage Of Bags": record.shortage_of_bags,
      Comments: record.comments
    }));

    const csv = convertToCSV({
      data
    });
    downloadCSV(csv, "Incoming Materials Export");
  });
};

async function run(fetchRelatedRecords, records) {
  const vendors = await fetchRelatedRecords(records, "vendor_id", "vendors");
  const clients = await fetchRelatedRecords(records, "client_id", "clients");
  const products = await fetchRelatedRecords(records, "product_id", "products");
  return { clients, vendors, products };
}
