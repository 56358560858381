import get from "lodash/get";
import camelCase from "lodash/camelCase";
import pickBy from "lodash/pickBy";
import values from "lodash/values";

import map from "lodash/fp/map";

import * as resources from "./resources";
import { getClient } from "./providers/dataProvider";

const methodMap = {
  can_create: "create",
  can_edit: "edit",
  can_read: "list",
  can_delete: "delete"
};

const buildMethods = layout =>
  values(pickBy(methodMap, (value, key) => layout[key]));

const renderResource = resource =>
  get(resources, resource.name, () => null)(resource.methods);

const buildResources = map(layout =>
  renderResource({
    name: camelCase(layout.page),
    methods: buildMethods(layout)
  })
);

export default async function permissionBasedResources(role) {
  const client = getClient();
  const response = await client.get(
    `/role_layouts?select=*,layout:layout_id(*,layout_fields(*, field:field_id(*)))&role=eq.${role}`
  );
  return buildResources(response.data);
}
