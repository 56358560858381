import React from "react";
import { withStyles } from "@material-ui/core";
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField
} from "react-admin";
import exporter from "./exporter";
import Filters from "./Filter";
import OutgoingPanel from "./expand";

export const FormListWithoutStyles = ({ permissions, ...props }) => {
  const permissionBasedProps = {};
  if (permissions && !permissions.includes("admin")) {
    permissionBasedProps.bulkActionButtons = false;
  }

  return (
    <List
      {...props}
      {...permissionBasedProps}
      sort={{ field: "outgoing_date", order: "DESC" }}
      title="Outgoing Material"
      exporter={exporter}
      perPage={25}
      filters={<Filters />}
    >
      <Datagrid
        expand={<OutgoingPanel />}
        rowClick="edit"
        classes={{ rowCell: props.classes.rowCell }}
      >
        <NumberField
          label="Outward Receipt No."
          source="incoming_material_id"
        />
        <ReferenceField source="client_id" linkType={false} reference="clients">
          <TextField source="company_name" label="Client" />
        </ReferenceField>

        <ReferenceField
          source="product_id"
          linkType={false}
          reference="products"
        >
          <TextField source="name" label="Product" />
        </ReferenceField>

        <DateField source="outgoing_date" label="Outgoing Date" />
        <TextField source="outgoing_time" label="Outgoing Time" />
        <NumberField source="number_of_bags" label="No. of Bags" />
        <NumberField source="outward_weight" label="Outgoing Wt." />
      </Datagrid>
    </List>
  );
};
const styles = {
  rowCell: {
    whiteSpace: "nowrap"
  }
};
export default withStyles(styles)(FormListWithoutStyles);
