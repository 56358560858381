import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS
} from "react-admin";
import axios from "axios";
import jwtDecode from "jwt-decode";

let client;
export default function createAuthProvider(options) {
  client = axios.create({
    baseURL: options.host || "http://localhost"
  });
  return authProvider;
}

function setToken(token) {
  window.localStorage.setItem("jwt_token", token);
}

function getToken() {
  return window.localStorage.getItem("jwt_token");
}

function removeToken() {
  window.localStorage.removeItem("jwt_token");
}

async function authProvider(type, params) {
  if (type === AUTH_LOGIN) {
    const response = await client.post("auth", params);
    setToken(response.data.jwtToken);
    return Promise.resolve();
  }

  if (type === AUTH_LOGOUT) {
    removeToken();
    return Promise.resolve();
  }

  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      removeToken();
    }
    return Promise.resolve();
  }

  if (type === AUTH_CHECK) {
    if (!getToken()) {
      throw new Error("Auth check failed. Try again!");
    }
    return Promise.resolve();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    let decoded = jwtDecode(getToken());
    return decoded.role;
  }

  throw new Error("Unknown method");
}
