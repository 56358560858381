import React from "react";
import {withStyles} from "@material-ui/core";
import Filter from "./Filter";

import {Datagrid, DateField, List, NumberField, ReferenceField, TextField} from "react-admin";
import exporter from "./exporter";

export const ListWithoutStyles = (props) => (
    <List
        bulkActionButtons={false}
        {...props}
        sort={{field: "trans_date", order: "DESC"}}
        exporter={exporter}
        title="Daily Report"
        filters={<Filter/>}
    >
        <Datagrid rowClick="edit">
            <DateField source="trans_date" sortable label="Date"/>
            <ReferenceField source="trans_clientid" reference="clients" label="Client">
                <TextField source="company_name" />
            </ReferenceField>
            <TextField source="trans_productname" label="Product"/>
            <NumberField source="trans_numberofbag" label="No. of Bags"/>
            <TextField source="trans_status" label="Status"/>
        </Datagrid>
    </List>
);
const styles = {
    rowCell: {
        whiteSpace: "nowrap",
    },
};
export default withStyles(styles)(ListWithoutStyles);