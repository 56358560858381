import React from "react";
import { Filter, NumberInput, ReferenceInput, SelectInput } from "react-admin";

export default props => (
  <Filter {...props}>
    <NumberInput label="Goods Receipt No." source="goodsReceiptNo" />
    <ReferenceInput source="vendor" reference="vendors">
      <SelectInput optionText="companyName" label="Client Source" />
    </ReferenceInput>
    <ReferenceInput source="client" reference="clients">
      <SelectInput optionText="companyName" label="Client" />
    </ReferenceInput>
    <ReferenceInput perPage={100} source="product" reference="products">
      <SelectInput optionText="name" label="Product" />
    </ReferenceInput>
  </Filter>
);
