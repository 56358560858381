import React from "react";
import {
  List,
  Edit,
  Create,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput
} from "react-admin";
import LocalFlorist from "@material-ui/icons/LocalFlorist";
import Filter from "./Filter";

export const ProductList = props => (
  <List {...props} filters={<Filter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" sortable />
      <TextField source="description" />
    </Datagrid>
  </List>
);

export const ProductEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
    </SimpleForm>
  </Edit>
);

export const ProductCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
    </SimpleForm>
  </Create>
);

export const ProductIcon = LocalFlorist;
