import React from "react";
import {withStyles} from "@material-ui/core";

import {Datagrid, DateField, List, NumberField, ReferenceField, TextField} from "react-admin";
import exporter from "./exporter";
import Filters from "./Filters";

export const FormListWithoutStyles = ({ permissions, ...props }) => (
         <List
           {...props}
           bulkActionButtons={false}
           sort={{ field: "goodsReceiptNo", order: "DESC" }}
           title="Condensed Balance Sheet"
           perPage={25}
           exporter={exporter}
           filters={<Filters />}
         >
           <Datagrid
             rowClick="edit"
             classes={{ rowCell: props.classes.rowCell }}
           >
             <NumberField source="goodsReceiptNo" label="Goods Receipt No." />
             <DateField source="date" label="Unload Date" />
             <DateField
               source="unloadTime"
               label="Unload Time"
               showTime
               options={{ hour: "2-digit", minute: "2-digit" }}
             />
             <ReferenceField
               source="client"
               linkType={false}
               reference="clients"
             >
               <TextField source="companyName" label="Client" />
             </ReferenceField>
             <ReferenceField
               source="vendor"
               linkType={false}
               reference="vendors"
             >
               <TextField source="companyName" label="Vendor" />
             </ReferenceField>
             <TextField source="vehicleNumber" label="Vehicle No." />
             <TextField source="chamber" label="Chamber" />
             <TextField source="cellNumber" label="Cell No." />
             <ReferenceField
               source="product"
               linkType={false}
               reference="products"
             >
               <TextField source="name" label="Product" />
             </ReferenceField>
             <NumberField source="numberOfBags" label="Incoming No. of Bags" />
             <NumberField
               source="outgoingNumberOfBags"
               label="Outgoing No. of Bags"
             />
             <NumberField
               source="balanceNumberOfBags"
               label="Balance No. of Bags"
             />
           </Datagrid>
         </List>
       );
const styles = {
  rowCell: {
    whiteSpace: "nowrap"
  }
};
export default withStyles(styles)(FormListWithoutStyles);
