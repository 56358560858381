import React from "react";
import {
  AutocompleteInput,
  LongTextInput,
  NumberInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  TextInput
} from "react-admin";
import { Field, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";

import { DateInput, TimeInput } from "../../components/dateTimeInput";
import FileUpload from "../../components/fileUpload";
import IncomingMaterialQuickPreview from "./IncomingMaterialQuickPreview";
import Typography from "@material-ui/core/Typography";

const shortid = require("shortid");
const generateFileName = () => {
  return `outgoing-${shortid.generate()}`;
};

export function Form() {
  return (
    <Grid container spacing={24}>
      <Grid item lg={8} md={8} sm={12} xs={12}>
        <Grid container spacing={24}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <ReferenceInput
              fullWidth
              source="incoming_material_id"
              reference="incoming_materials"
              label="Goods Receipt No"
              perPage={400}
              filterToQuery={searchText => ({ id: searchText })}
            >
              <AutocompleteInput
                fullWidth
                label="Goods Receipt No"
                optionText="id"
              />
            </ReferenceInput>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={24}>
              <Grid item>
                <DateInput
                  fullWidth
                  source="outgoing_date"
                  label="Outgoing Date"
                />
              </Grid>
              <Grid item>
                <TimeInput
                  fullWidth
                  source="outgoing_time"
                  label="Outgoing Time"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextInput
              fullWidth
              source="vehicle_number"
              label="Vehicle Number"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <NumberInput
              fullWidth
              source="outward_weight"
              label="Outward Weight"
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <NumberInput
              fullWidth
              source="number_of_bags"
              label="Number of Bags"
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <LongTextInput
              fullWidth
              source="comments"
              label="Any remarks or comments?"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FileUpload
              name="invoice_img"
              resource="outgoing"
              label="Invoice Image"
              goodsReceiptNo={generateFileName()}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FileUpload
              name="waybridge_img"
              resource="outgoing"
              label="Waybridge Image"
              goodsReceiptNo={generateFileName()}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FileUpload
              name="other_img"
              resource="outgoing"
              label="Any other Image"
              goodsReceiptNo={generateFileName()}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <Field
          name="incoming_material_id"
          component={({ input }) =>
            input.value && (
              <>
                <Typography style={{ paddingLeft: "16px" }} variant="title">
                  Related Incoming Material
                </Typography>
                <IncomingMaterialQuickPreview id={input.value} />
              </>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const formSelector = formValueSelector(REDUX_FORM_NAME);

function mapStateToProps(state) {
  return {
    id: formSelector(state, "id")
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
