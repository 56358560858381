import React, { Component } from "react";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { createStyles, withStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FormLabel from "@material-ui/core/FormLabel";
import classnames from "classnames";
import FileDownload from "../../components/fileDownload";

const styles = theme =>
  createStyles({
    title: {
      fontSize: 14
    },
    pos: {
      marginBottom: 12
    },
    container: {
      padding: "0 12px"
    },
    actions: {
      display: "flex"
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  });

class CardList extends Component {
  state = {
    expanded: null
  };
  handleClick = id => {
    return () => {
      if (this.state.expanded !== id) {
        this.setState({ expanded: id });
        return;
      }
      this.setState({ expanded: null });
    };
  };

  render() {
    let { classes, data, ids } = this.props;
    return (
      <Grid container spacing={16} className={classes.container}>
        {ids.map(id => {
          const record = data[id];
          let expanded = this.state.expanded === id;
          return (
            <Grid item lg={12} xs={12} md={12} sm={12}>
              <Card>
                <CardContent>
                  <Typography
                    className={classes.title}
                    color="textSecondary"
                    gutterBottom
                  >
                    {record.incoming_date} from
                    <Typography inline color="textPrimary">
                      {" "}
                      {record.client_name}
                    </Typography>
                  </Typography>
                  <Typography variant="h6" component="h2">
                    {record.product_name}
                  </Typography>
                  <Typography className={classes.pos} color="textSecondary">
                    {record.number_of_bags} bags, {record.invoice_weight} KG
                  </Typography>
                  <Chip
                    variant="outlined"
                    label={`${record.cell_number} in Chamber ${record.chamber}`}
                  />
                </CardContent>
                <CardActions className={classes.actions} disableActionSpacing>
                  <FileDownload
                    source="invoice_img"
                    record={record}
                    label="Invoice"
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <FileDownload
                    source="waybridge_img"
                    record={record}
                    label="Waybridge"
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <FileDownload
                    source="other_img"
                    record={record}
                    label="Other"
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                  <IconButton
                    className={classnames(classes.expand, {
                      [classes.expandOpen]: expanded
                    })}
                    onClick={this.handleClick(id)}
                    aria-expanded={expanded}
                    aria-label="Show more"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                </CardActions>

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <FormLabel>Comment</FormLabel>
                    <Typography paragraph>
                      {record.comment || "None."}
                    </Typography>
                  </CardContent>
                </Collapse>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    );
  }
}

CardList.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.object,
  hasBulkActions: PropTypes.bool.isRequired,
  ids: PropTypes.array,
  leftAvatar: PropTypes.func,
  leftIcon: PropTypes.func,
  linkType: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  onToggleItem: PropTypes.func,
  primaryText: PropTypes.func,
  rightAvatar: PropTypes.func,
  rightIcon: PropTypes.func,
  secondaryText: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
  tertiaryText: PropTypes.func
};

CardList.defaultProps = {
  linkType: "edit",
  hasBulkActions: false,
  selectedIds: []
};

export default withStyles(styles)(CardList);
