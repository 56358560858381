import React from "react";
import {
  DateField,
  EditButton,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField
} from "react-admin";
import CardActions from "@material-ui/core/CardActions";
import FileCopy from "@material-ui/icons/FileCopy";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import pick from "lodash/pick";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const leftIconStyle = {
  marginRight: 10
};

const OutgoingMaterialActions = props => {
  const { basePath, data } = props;
  const cloneFields = [
    "date",
    "unloadTime",
    "vehicleNumber",
    "invoiceImg",
    "waybridgeImg",
    "otherImg"
  ];
  return (
    <CardActions style={cardActionStyle}>
      <EditButton basePath={basePath} record={data} />

      <Button
        component={Link}
        to={{
          pathname: "/outgoing-materials/create",
          state: { record: pick(data, cloneFields) }
        }}
        color="primary"
        size="small"
      >
        <FileCopy style={leftIconStyle} />
        Create Similar Record
      </Button>
    </CardActions>
  );
};

export const OutgoingMaterialsShow = props => {
  return (
    <Show
      {...props}
      actions={<OutgoingMaterialActions />}
      title="Outgoing Material"
    >
      <SimpleShowLayout>
        <ReferenceField
          source="incomingMaterialId"
          linkType={false}
          reference="forms"
          label="Outward Receipt No."
        >
          <NumberField source="goodsReceiptNo" label="Outward Receipt No." />
        </ReferenceField>

        <DateField source="date" label="Outgoing Date" />
        <DateField
          source="unloadTime"
          label="Outgoing Time"
          showTime
          options={{ hour: "2-digit", minute: "2-digit" }}
        />
        <TextField source="vehicleNumber" label="Vehicle No." />
        <NumberField source="numberOfBags" label="No. of Bags" />
        <NumberField source="outwardWeight" label="Outgoing Wt." />
        <NumberField source="avgWeight" label="Average Wt." />
        <TextField source="comments" label="Comments" />
      </SimpleShowLayout>
    </Show>
  );
};

export default OutgoingMaterialsShow;
