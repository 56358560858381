import React from "react";
import { Create, SimpleForm } from "react-admin";

import Form from "./CreateForm";

export default class FormCreate extends React.Component {
  render() {
    const { props } = this;
    return (
      <Create {...props} title="Incoming Material">
        <SimpleForm redirect="list">
          <Form />
        </SimpleForm>
      </Create>
    );
  }
}
