import { CREATE } from "react-admin";
import { getFormattedTimeForPostgres } from "../utils/dates";
import { compose } from "redux";
const customIncomingUpload = handler => (type, resource, params) => {
  if (type !== CREATE || resource !== "incoming_materials") {
    return handler(type, resource, params);
  }

  const { variables, ...data } = params.data;
  const formattedData = variables.map(variable => {
    const formatted = {
      ...data,
      incoming_time: getFormattedTimeForPostgres(data.incoming_time),
      ...variable
    };
    const formattedParams = {
      ...params,
      data: formatted
    };
    return handler(type, resource, formattedParams);
  });

  return Promise.all(formattedData).then(result => {
    return result[0];
  });
};

const customOutgoingUpload = handler => (type, resource, params) => {
  if (type !== CREATE || resource !== "outgoing_materials") {
    return handler(type, resource, params);
  }

  const { variables, ...data } = params.data;
  const formattedData = variables.map(variable => {
    const formatted = {
      ...data,
      outgoing_time: getFormattedTimeForPostgres(data.outgoing_time),
      ...variable
    };
    const formattedParams = {
      ...params,
      data: formatted
    };
    return handler(type, resource, formattedParams);
  });

  return Promise.all(formattedData).then(result => {
    return result[0];
  });
};

export default compose(
  customIncomingUpload,
  customOutgoingUpload
);
