import React, { Component } from "react";
import { Field } from "redux-form";

import Uppy from "@uppy/core";
import awsS3 from "@uppy/aws-s3";
import { Dashboard } from "@uppy/react";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "./style.css";

import axios from "axios";

const UPLOADING_STATE = "uploading";

const notNull = value => {
  return value === UPLOADING_STATE
    ? new Error("File upload in progress")
    : undefined;
};

export class FileInput extends Component {
  state = {
    downloading: false
  };

  constructor(props) {
    super(props);
    this.setupUppy();
  }

  setupUppy() {
    this.uppy = Uppy({
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 1,
        allowedFileTypes: ["image/*", ".pdf"]
      },
      onBeforeUpload: this.onBeforeUpload
    });
    this.uppy.use(awsS3, {
      getUploadParameters: this.getUploadParams
    });
    this.uppy.on("upload-success", this.uploadSuccess);
    this.uppy.on("cancel-all", this.cancelAll);
  }

  componentWillUnmount() {
    this.uppy.close();
    this.uppy.off("upload-success", this.uploadSuccess);
    this.uppy.off("cancel-all", this.cancelAll);
  }

  componentDidMount() {
    if (this.props.meta.initial) {
      return this.addInitialFilesToUppy();
    }
  }

  cancelAll = () => {
    this.props.input.onChange(null);
  };

  uploadSuccess = file => {
    this.props.input.onChange(this.getFileKey(file));
  };

  getFileKey = file =>
    `${this.props.input.name}-${
      this.props.goodsReceiptNo ? this.props.goodsReceiptNo : ""
    }-${this.props.resource}.${file.extension}`;

  onBeforeUpload = files => {
    if (!this.props.goodsReceiptNo && this.props.resource !== "outgoing") {
      this.uppy.info(
        `Goods Receipt No. is required before uploading`,
        "error",
        3000
      );
      return false;
    }
    const fileName = Object.keys(files)[0];
    if (files[fileName].source !== "") {
      this.props.input.onChange(UPLOADING_STATE);
    }
  };

  getUploadParams = async file => {
    const contentType = file.meta.type;
    const response = await axios.post(
      "https://gub1pjgc00.execute-api.us-east-1.amazonaws.com/latest/files",
      {
        type: contentType,
        id: this.getFileKey(file),
        method: "putObject"
      }
    );
    const { signedUrl } = response.data;
    return {
      method: "PUT",
      url: signedUrl,
      fields: [],
      headers: { ContentType: contentType }
    };
  };

  getDownloadUrl = async key => {
    const response = await axios.post(
      "https://gub1pjgc00.execute-api.us-east-1.amazonaws.com/latest/files",
      {
        id: key,
        method: "getObject"
      }
    );
    return response.data.signedUrl;
  };

  addInitialFilesToUppy = async () => {
    this.setState({ downloading: true });
    const imgUrl = await this.getDownloadUrl(this.props.meta.initial);
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    this.uppy.addFile({
      name: this.props.meta.initial,
      type: blob.type,
      data: blob
    });
    this.setState({ downloading: false });
    Object.keys(this.uppy.state.files).forEach(fileID => {
      this.uppy.setFileState(fileID, {
        progress: { uploadComplete: true, uploadStarted: true }
      });
    });
  };

  render() {
    return (
      <Dashboard
        height={90}
        width="100%"
        uppy={this.uppy}
        showProgressDetails
        proudlyDisplayPoweredByUppy={false}
        note={this.props.label}
        locale={{
          strings: {
            dropPaste: `Drop the file here or %{browse}`,
            cancel: "Reset",
            xFilesSelected: {
              0: "",
              1: ""
            }
          }
        }}
      />
    );
  }
}

export default props => {
  return (
    <Field
      component={FileInput}
      validate={notNull}
      name={props.name}
      label={props.label}
      resource={props.resource}
      goodsReceiptNo={props.goodsReceiptNo}
    />
  );
};
