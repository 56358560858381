import {downloadCSV} from "react-admin";
import {unparse as convertToCSV} from "papaparse/papaparse.min";
import {getFormattedDateForExcel} from "../../utils/dates";

export default (records, fetchRelatedRecords) => {
    run(fetchRelatedRecords, records).then(({clients}) => {
        const data = records.map(record => ({
            "Date": getFormattedDateForExcel(record.trans_date),
            "Client": clients[record.trans_clientid].company_name,
            "Product": record.trans_productname,
            "No. of Bags": record.trans_numberofbag,
            "Status": record.trans_status,
        }));

        const csv = convertToCSV({
            data
        });
        downloadCSV(csv, "Daily Report Export");
    });
};

async function run(fetchRelatedRecords, records) {
    const clients = await fetchRelatedRecords(records, "trans_clientid", "clients");
    return {clients};
}
