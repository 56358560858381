import React from "react";
import { Filter, ReferenceInput, SelectInput } from "react-admin";

export default (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Client"
      source="client_id@eq"
      reference="clients"
      perPage={500}
      sort={{ field: "company_name", order: "ASC" }}
    >
      <SelectInput optionText="company_name" label="Client" />
    </ReferenceInput>
    <ReferenceInput
      label="Product"
      perPage={500}
      source="product_id@eq"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" label="Product" />
    </ReferenceInput>
  </Filter>
);
