import React from "react";
import { Admin } from "react-admin";
import { CssBaseline } from "@material-ui/core";

import providers from "./providers";
import CustomLayout from "./components/customLayout";
import permissionBasedResources from "./permissions";
import Login from "./components/loginPage";
import enhanceDataProvider from "./providers/enhancers";

const dataProvider = enhanceDataProvider(providers.dataProvider);

function App() {
  return (
    <>
      <CssBaseline />
      <Admin
        loginPage={Login}
        appLayout={CustomLayout}
        title="Harvish Cold Storage"
        authProvider={providers.authProvider}
        dataProvider={dataProvider}
      >
        {permissionBasedResources}
      </Admin>
    </>
  );
}

export default App;
