import React from "react";
import {
  ArrayInput,
  AutocompleteInput,
  FormDataConsumer,
  LongTextInput,
  NumberInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  SimpleFormIterator,
  TextInput
} from "react-admin";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { Grid } from "@material-ui/core";
import _get from "lodash/get";

import { DateInput, TimeInput } from "../../components/dateTimeInput";
import FileUpload from "../../components/fileUpload";
import IncomingMaterialQuickPreview from "./IncomingMaterialQuickPreview";

const shortid = require("shortid");

const generateFileName = () => {
  return `outgoing-${shortid.generate()}`;
};

export function Form() {
  return (
    <Grid container spacing={24}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={24}>
          <Grid item>
            <DateInput fullWidth source="outgoing_date" label="Outgoing Date" />
          </Grid>
          <Grid item>
            <TimeInput fullWidth source="outgoing_time" label="Outgoing Time" />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextInput fullWidth source="vehicle_number" label="Vehicle Number" />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <ArrayInput source="variables" label="Product Information">
          <SimpleFormIterator>
            <ReferenceInput
              source="incoming_material_id"
              reference="incoming_materials"
              label="Goods Receipt No"
              perPage={400}
              filterToQuery={searchText => ({ id: searchText })}
            >
              <AutocompleteInput
                fullWidth
                label="Goods Receipt No"
                optionText="id"
              />
            </ReferenceInput>
            <FormDataConsumer>
              {props => {
                const id = _get(props, "scopedFormData.incoming_material_id");
                return id && <IncomingMaterialQuickPreview id={id} />;
              }}
            </FormDataConsumer>
            <NumberInput source="outward_weight" label="Outward Weight" />
            <NumberInput source="number_of_bags" label="Number of Bags" />
            <LongTextInput source="comments" label="Any remarks or comments?" />
          </SimpleFormIterator>
        </ArrayInput>
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="invoice_img"
          resource="outgoing"
          label="Invoice Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="waybridge_img"
          resource="outgoing"
          label="Waybridge Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
      <Grid item lg={4} md={4} sm={12} xs={12}>
        <FileUpload
          name="other_img"
          resource="outgoing"
          label="Any other Image"
          goodsReceiptNo={generateFileName()}
        />
      </Grid>
    </Grid>
  );
}

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

const formSelector = formValueSelector(REDUX_FORM_NAME);

function mapStateToProps(state) {
  return {
    incomingMaterialId: formSelector(state, "incoming_material_id")
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
