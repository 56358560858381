import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import FileDownload from "../../components/fileDownload";

const styles = {
  card: {
    boxShadow: "none"
  }
};

function OutgoingPanel(props) {
  return (
    <Grid container spacing={24} justify="space-between">
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Show {...props} title=" ">
          <SimpleShowLayout>
            <TextField source="vehicle_number" label="Vehicle No." />
            <TextField source="comments" label="Comments" />
          </SimpleShowLayout>
        </Show>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
        <Show {...props} title=" ">
          <SimpleShowLayout>
            <FileDownload
              size="small"
              source="invoice_img"
              label="Invoice Image"
            />
            <FileDownload
              size="small"
              source="waybridge_img"
              label="Waybridge Image"
            />
            <FileDownload size="small" source="other_img" label="Other Image" />
          </SimpleShowLayout>
        </Show>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(OutgoingPanel);
