import { downloadCSV } from "react-admin";
import { unparse as convertToCSV } from "papaparse/papaparse.min";

export default (records, fetchRelatedRecords) => {
  run(fetchRelatedRecords, records).then(({ clients, vendors, products }) => {
    const data = records.map(record => ({
      "Goods Receipt No": record["goodsReceiptNo"],
      "Unload Date": record["date"],
      "Unload Time": record["unloadTime"],
      Chamber: record["chamber"],
      "Cell Number": record["cellNumber"],
      Client: clients[record.client].companyName,
      Vendor: vendors[record.vendor].companyName,
      Product: products[record.product].name,
      "Incoming No. Of Bags": record["numberOfBags"],
      "Outgoing No. Of Bags": record["outgoingNumberOfBags"],
      "Balance No. Of Bags": record["balanceNumberOfBags"],
      Comments: record["comments"]
    }));

    const csv = convertToCSV({
      data
    });
    downloadCSV(csv, "Balance Export");
  });
};

async function run(fetchRelatedRecords, records) {
  const clients = await fetchRelatedRecords(records, "client", "clients");
  const vendors = await fetchRelatedRecords(records, "vendor", "vendors");
  const products = await fetchRelatedRecords(records, "product", "products");
  return { clients, vendors, products };
}
