// Import here Polyfills if needed. Recommended core-js (npm i -D core-js)
import axios from "axios";
import { stringify } from "qs";
import isNil from "lodash/isNil";
import reject from "lodash/reject";
import reduce from "lodash/reduce";
import isArray from "lodash/isArray";

let client;
function defaultExtractJwtToken() {
  return window.localStorage.getItem("jwt_token");
}
function createAxiosClient(options) {
  const {
    host = "http://localhost",
    port = "",
    extractToken = defaultExtractJwtToken,
  } = options;
  const client = axios.create({
    baseURL: `${host}${port === "" ? "" : `:${port}`}`,
  });
  client.interceptors.request.use(function (config) {
    config.headers = config.headers || {};
    let token = extractToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });
  return client;
}
export default function createDataProvider(options) {
  const { createClient = createAxiosClient } = options;
  client = createClient(options);
  return dataProvider;
}
function dataProvider(type, resource, params) {
  switch (type) {
    case "GET_LIST": {
      return getList(resource, params);
    }
    case "GET_ONE": {
      return getOne(resource, params);
    }
    case "CREATE": {
      return create(resource, params);
    }
    case "UPDATE": {
      return update(resource, params);
    }
    case "UPDATE_MANY": {
      return updateMany(resource, params);
    }
    case "DELETE": {
      return del(resource, params);
    }
    case "DELETE_MANY": {
      return delMany(resource, params);
    }
    case "GET_MANY": {
      return getMany(resource, params);
    }
    case "GET_MANY_REFERENCE": {
      return getManyReference(resource, params);
    }
    default: {
      throw new Error(`Bad data provider type: ${type}`);
    }
  }
}
async function getList(resource, params) {
  if (!client) {
    throw new Error("Cannot create http client");
  }

  const {
    pagination = {},
    sort = {},
    filter = {},
    headers: givenHeaders = {},
  } = params;
  const { page = 1, perPage = 25 } = pagination;
  const { field, order } = sort;
  const recordsTo = page * perPage;
  const recordsFrom = recordsTo - perPage;
  const headers = {
    Prefer: "count=exact",
    Range: `${recordsFrom}-${recordsTo - 1}`,
    ...givenHeaders,
  };
  const query = Object.assign({}, formatFilter(filter));
  if (order && field) {
    query.order = `${field}.${order.toLowerCase()}`;
  }
  const queryString = stringify(query, { addQueryPrefix: true });
  const response = await client.get(`/${resource}${queryString}`, {
    headers,
  });

  if (headers.Accept === "text/csv") {
    return response.data;
  }

  return {
    data: response.data.map((row, idx) => ensureId(row, idx + 1)),
    total: parseInt(response.headers["content-range"].split("/")[1], 10),
  };
}
async function getOne(resource, params) {
  const response = await client.get(`${resource}?id=eq.${params.id}`, {
    headers: {
      accept: "application/vnd.pgrst.object+json",
    },
  });
  return { data: response.data };
}
async function create(resource, params) {
  const headers = {
    Prefer: "return=representation",
  };
  if (!isArray(params.data)) {
    headers.accept = "application/vnd.pgrst.object+json";
  }

  const response = await client.post(resource, params.data, {
    headers,
  });
  return { data: response.data };
}
async function update(resource, params) {
  const { id, previousData = {}, data = {} } = params;
  const response = await client.patch(
    `${resource}?id=eq.${id}`,
    Object.assign(Object.assign({}, previousData), data),
    {
      headers: {
        Prefer: "return=representation",
        accept: "application/vnd.pgrst.object+json",
      },
    }
  );
  return {
    data: response.data,
  };
}
async function updateMany(resource, params) {
  const { ids, data = {} } = params;
  const response = await client.patch(
    `${resource}?id=in.(${joinWithoutEmpty(ids)})`,
    data,
    {
      headers: {
        Prefer: "return=representation",
      },
    }
  );
  return {
    data: response.data,
  };
}
async function del(resource, params) {
  const response = await client.delete(`${resource}?id=eq.${params.id}`, {
    headers: {
      Prefer: "return=representation",
      accept: "application/vnd.pgrst.object+json",
    },
  });
  return {
    data: response.data,
  };
}
async function delMany(resource, params) {
  const response = await client.delete(
    `${resource}?id=in.(${joinWithoutEmpty(params.ids)})`,
    {
      headers: {
        Prefer: "return=representation",
      },
    }
  );
  return {
    data: response.data,
  };
}
async function getMany(resource, params) {
  const response = await client.get(
    `${resource}?id=in.(${joinWithoutEmpty(params.ids)})`,
    {
      headers: {
        Prefer: "return=representation",
      },
    }
  );
  return {
    data: response.data,
  };
}
async function getManyReference(resource, params) {
  const { filter = {}, id, target, ...rest } = params;
  return getList(
    resource,
    Object.assign(Object.assign({}, rest), {
      filter: Object.assign(Object.assign({}, filter), {
        [target]: `eq.${id}`,
      }),
    })
  );
}

function ensureId(row, defaultId) {
  if (!row.id) {
    row.id = defaultId;
  }
  return row;
}

function joinWithoutEmpty(list) {
  return reject(list, isNil).join(",");
}

function formatFilter(given) {
  return reduce(
    given,
    (result, value, key) => {
      const filterValue = formatFilterValue(value);
      if (!filterValue) {
        return result;
      }

      if (key === "select") {
        result[key] = value;
        return result;
      }

      const [field, op = "eq"] = key.split("@");

      if (op === "like" || op === "ilike") {
        result[field] = `${op}.*${filterValue}*`;
        return result;
      }

      result[field] = `${op}.${filterValue}`;
      return result;
    },
    {}
  );
}

function formatFilterValue(value) {
  return isArray(value) ? `(${joinWithoutEmpty(value)})` : value;
}

export function getClient() {
  return client;
}
