import React from "react";
import { withStyles } from "@material-ui/core";

import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  Responsive
} from "react-admin";
import Filters from "./Filter";
import IncomingPanel from "./expand";
import exporter from "./exporter";
import CardList from "./CardList";

export const FormListWithoutStyles = ({ permissions, ...props }) => {
  const permissionBasedProps = {};
  if (permissions && !permissions.includes("admin")) {
    permissionBasedProps.bulkActionButtons = false;
  }

  const { rowCell, rowEven } = props.classes;
  return (
    <List
      {...props}
      {...permissionBasedProps}
      sort={{ field: "id", order: "DESC" }}
      title="Incoming Material"
      perPage={25}
      filters={<Filters />}
      exporter={exporter}
    >
      <Responsive
        medium={
          <Datagrid
            rowClick="edit"
            classes={{ rowCell, rowEven }}
            expand={<IncomingPanel />}
          >
            <TextField source="id" label="Goods Receipt No." />
            <DateField source="incoming_date" label="Unload Date" />
            <TextField source="incoming_time" label="Unload Time" />
            <ReferenceField
              source="client_id"
              linkType={false}
              reference="clients"
            >
              <TextField source="company_name" label="Client" />
            </ReferenceField>
            <ReferenceField
              source="vendor_id"
              linkType={false}
              reference="vendors"
            >
              <TextField source="company_name" label="Vendor" />
            </ReferenceField>
            <ReferenceField
              source="product_id"
              linkType={false}
              reference="products"
            >
              <TextField source="name" label="Product" />
            </ReferenceField>
            <NumberField source="number_of_bags" label="No. of Bags" />
            <NumberField source="invoice_weight" label="Invoice Wt." />
          </Datagrid>
        }
        small={<CardList />}
      />
    </List>
  );
};

const styles = theme => ({
  rowEven: {
    backgroundColor: theme.palette.background.default
  }
});

export default withStyles(styles)(FormListWithoutStyles);
