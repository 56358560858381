import React from "react";
import {
  Filter,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TextInput,
} from "react-admin";
import { DateInput } from "../../components/dateTimeInput";

export default (props) => (
  <Filter {...props}>
    <NumberInput label="Goods Receipt No." source="id@eq" />
    <ReferenceInput
      label="Client Source"
      source="vendor_id@eq"
      reference="vendors"
      perPage={200}
      sort={{ field: "company_name", order: "ASC" }}
    >
      <SelectInput optionText="company_name" label="Client Source" />
    </ReferenceInput>
    <ReferenceInput
      label="Client"
      source="client_id@eq"
      reference="clients"
      perPage={200}
      sort={{ field: "company_name", order: "ASC" }}
    >
      <SelectInput optionText="company_name" label="Client" />
    </ReferenceInput>
    <ReferenceInput
      label="Product"
      perPage={500}
      source="product_id@eq"
      reference="products"
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" label="Product" />
    </ReferenceInput>
    <TextInput source="vehicle_number@eq" label="Vehicle No." />
    <TextInput source="invoice_number@eq" label="Invoice Number" />
    <DateInput source="invoice_date@eq" label="Invoice Date" />
    <DateInput source="incoming_date@eq" label="Incoming Date" />
    <DateInput source="incoming_date@gte" label="From Date" />
    <DateInput source="incoming_date@lte" label="To Date" />
  </Filter>
);
