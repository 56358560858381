import React from "react";
import { Resource } from "react-admin";
import pick from "lodash/pick";
import {
  PricesCreate,
  PricesEdit,
  PricesIcon,
  PricesList
} from "./containers/prices";
import {
  ProductCreate,
  ProductEdit,
  ProductIcon,
  ProductList
} from "./containers/products";
import {
  ClientCreate,
  ClientEdit,
  ClientIcon,
  ClientList
} from "./containers/clients";
import {
  FormCreate,
  FormEdit,
  FormIcon,
  FormList,
  FormShow
} from "./containers/incomingMaterials";
import {
  OutgoingMaterialCreate,
  OutgoingMaterialEdit,
  OutgoingMaterialIcon,
  OutgoingMaterialList,
  OutgoingMaterialShow
} from "./containers/outgoingMaterials";
import { BalanceIcon, BalanceList } from "./containers/balance";
import {
  SpecialBalanceIcon,
  SpecialBalanceList
} from "./containers/specialBalance";
import { SummaryIcon, SummaryList } from "./containers/summary";
import { UserCreate, UserEdit, UserIcon, UserList } from "./containers/users";
import { SensorValuesIcon, SensorValuesList } from "./containers/sensorValues";
import { StockIcon, StockList } from "./containers/stock";
import {DailyReportIcon, DailyReportList} from "./containers/dailyReport";

export function products(methods) {
  const props = {
    list: ProductList,
    edit: ProductEdit,
    create: ProductCreate
  };
  const allowedProps = pick(props, methods);
  return (
    <Resource
      name="products"
      options={{ label: "Products" }}
      icon={ProductIcon}
      {...allowedProps}
    />
  );
}

export function prices(methods) {
  const props = {
    list: PricesList,
    edit: PricesEdit,
    create: PricesCreate
  };
  const allowedProps = pick(props, methods);
  return (
    <Resource
      name="prices"
      icon={PricesIcon}
      options={{ label: "Prices" }}
      {...allowedProps}
    />
  );
}

export function clients(methods) {
  const props = {
    list: ClientList,
    edit: ClientEdit,
    create: ClientCreate
  };
  const allowedProps = pick(props, methods);
  return (
    <Resource
      name="clients"
      icon={ClientIcon}
      options={{ label: "Clients" }}
      {...allowedProps}
    />
  );
}

export function vendors(methods) {
  const props = {
    list: ClientList,
    edit: ClientEdit,
    create: ClientCreate
  };
  const allowedProps = pick(props, methods);
  const label = "Client Sources";
  return (
    <Resource
      name="vendors"
      options={{ label }}
      icon={ClientIcon}
      {...allowedProps}
    />
  );
}

export function incomingMaterials(methods) {
  const props = {
    list: FormList,
    edit: FormEdit,
    create: FormCreate,
    show: FormShow
  };
  const allowedProps = pick(props, methods);
  const label = "Incoming Material";
  return (
    <Resource
      name="incoming_materials"
      options={{ label }}
      icon={FormIcon}
      {...allowedProps}
    />
  );
}

export function outgoingMaterials(methods) {
  const props = {
    list: OutgoingMaterialList,
    edit: OutgoingMaterialEdit,
    create: OutgoingMaterialCreate,
    show: OutgoingMaterialShow
  };
  const allowedProps = pick(props, methods);
  const label = "Outgoing Material";
  return (
    <Resource
      name="outgoing_materials"
      options={{ label }}
      icon={OutgoingMaterialIcon}
      {...allowedProps}
    />
  );
}

export function balance(methods) {
  const props = {
    list: BalanceList
  };
  const allowedProps = pick(props, methods);
  const label = "Balance";
  return (
    <Resource
      name="balance"
      options={{ label }}
      icon={BalanceIcon}
      {...allowedProps}
    />
  );
}

export function specialBalance(methods) {
  const props = {
    list: SpecialBalanceList
  };
  const allowedProps = pick(props, methods);
  const label = "Condensed Balance";
  return (
    <Resource
      name="special-balance"
      options={{ label }}
      icon={SpecialBalanceIcon}
      {...allowedProps}
    />
  );
}

export function summary(methods) {
  const props = {
    list: SummaryList
  };
  const allowedProps = pick(props, methods);
  const label = "Summary";
  return (
    <Resource
      name="summary"
      options={{ label }}
      icon={SummaryIcon}
      {...allowedProps}
    />
  );
}

export function stockReport(methods) {
  const props = {
    list: StockList
  };
  const allowedProps = pick(props, methods);
  const label = "Stock";
  return (
    <Resource
      name="stock_report"
      options={{ label }}
      icon={StockIcon}
      {...allowedProps}
    />
  );
}

export function sensorSamples(methods) {
  const props = {
    list: SensorValuesList
  };
  const allowedProps = pick(props, methods);
  const label = "Sensor Data";
  return (
    <Resource
      name="sensor_samples"
      options={{ label }}
      icon={SensorValuesIcon}
      {...allowedProps}
    />
  );
}

export function dailyReport(methods) {
  const props = {
    list: DailyReportList
  };
  const allowedProps = pick(props, methods);
  const label = "Daily Report";
  return (
    <Resource
      name="vw_daily_report"
      options={{ label }}
      icon={DailyReportIcon}
      {...allowedProps}
    />
  );
}

export function users(methods) {
  const props = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit
  };
  const allowedProps = pick(props, methods);
  const label = "Users";
  return (
    <Resource
      name="users"
      options={{ label }}
      icon={UserIcon}
      {...allowedProps}
    />
  );
}
